.modalContextRoot {
  position: relative;
}

.modalElementContainer {
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transition: all 200ms linear;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--color-gray5-blurred);

  &.active {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.modalElement {
  height: 80%;
  width: 74%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
}

.closeButton {
  position: absolute;
  top: calc(10% + 24px);
  right: calc(13% + 24px);
  width: 24px;
  height: 24px;
  background-image: url(../images/close-gray1.svg);
  background-size: 24px;
}