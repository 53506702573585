.leftNav {
  background-color: var(--color-gray5);
  padding: 28px 8px 8px 12px;
  overflow: auto;
  height: 90vh;

  a {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-black);
    &:hover {
      color: var(--color-black);
    }
    &:visited {
      color: var(--color-black);
    }
  }
}

.topicsHeading, .interviewsHeading, .favoritesHeading, .questionsHeading {
  background-repeat: no-repeat;
  background-position: 4px 50%;
  background-size: 24px;
  padding: 6px 4px 6px 32px;
  margin: 40px 0 0 0;
}

.topicsHeading {
  margin-top: 6px;
  background-image: url(../images/topic.svg);

  &.selected {
    background-image: url(../images/topic-selected.svg);
    background-color: var(--color-gray2);
    border-radius: 8px;
    a {
      color: var(--color-white);
    }
  }
}

.reviewTopicsButton {
  margin: 16px 16px;
}

.interviewsHeading, .questionsHeading {
  background-image: url(../images/people.svg);

  &.selected {
    background-image: url(../images/people-selected.svg);
    background-color: var(--color-gray2);
    border-radius: 8px;
    a {
      color: var(--color-white);
    }
  }
}

.favoritesHeading {
  background-image: url(../images/star-fill.svg);
  display: flex;
  justify-content: space-between;

  .favoritesLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .numFavorites {
    margin-right: 8px;
  }

  &.selected {
    background-image: url(../images/star-fill-white.svg);
    background-color: var(--color-gray2);
    border-radius: 8px;

    .favoritesLink {
      color: var(--color-white);
    }
  }
}

.topics {
  list-style: none;
  margin: 0;
  padding: 0;

  .topicLink {
    display: flex;
    margin: 8px 0;
    padding: 4px 8px 4px 32px;

    &.selected {
      background-color: var(--color-gray2);
      color: var(--color-white);
      border-radius: 8px;
    }

    h4 {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 160%;
      max-width: 100%;
      overflow: hidden;

    }
  }
}
