.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.scrollingContainer {
  width: 100%;
  height: calc(100% - 67px);
  padding: 32px 0 0 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.dialogControls {
  position: absolute;
  height: 67px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 32px;

  background-color: var(--color-white);
  border-top: 1px solid var(--color-gray5);

  display: flex;
  justify-content: flex-end;

  button {
    margin: 0 8px;
  }
}

.interviewGuideEditor {
  .guideControls {
    padding: 16px 0;
    display: flex;
    justify-content: center;
  }
}

.interviewThemeEditor {
  position: relative;
  padding: 32px 0;
  border-bottom: 1px solid var(--color-gray3);
  width: 80%;
  margin: 0 auto;
  .themeInput,
  .descriptionInput,
  .questions {
    margin-bottom: 8px;
  }

  .themeInput input {
    width: 100%;
  }
  .descriptionInput textarea {
    width: 100%;
    height: 8em;
  }

  .questions {
    input {
      width: 80%;
    }

    .questionsControls {
      padding: 8px 0;
      display: flex;
      justify-content: flex-end;
    }

    .question {
      position: relative;
      margin-bottom: 8px;

      .questionControls {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;

        .deleteQuestionButton {
          background-image: url(../images/close.svg);
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: 0 0;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .themeControls {
    padding: 8px 0;
    display: flex;
    justify-content: flex-end;
  }
}
