.projectSettings {
  position: relative;
}

.subnav {
  background-color: var(--color-white);

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95px;
  padding: 32px 16px 24px 20px;

  .heading {
    margin: 0;
  }
}

.scrollingContainer {
  overflow-y: auto;
  height: calc(100% - 95px);
}

.content {
  height: fit-content;
  overflow: auto;

  h2 {
    margin-bottom: 24px;
  }
}

.formCaption {
  margin-top: 24px;
}

.input {
  margin-top: 8px;
  width: 48%;
}

.textarea {
  display: block;
  margin-top: 8px;
  width: 72%;
  height: 12em;
  padding: 16px;
  border: 1px solid var(--color-gray3);
  border-radius: 4px;
}

.progressIndicator {
  background-image: url(../images/progress-indicator.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 63px 9px;
  padding: 16px 16px 16px 72px;
}

.processingDone {
  background-image: url(../images/done-green.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  padding: 16px 16px 16px 28px;  
}

.assetsContainer,
.stepsContainer,
.analysisContainer,
.detailsContainer,
.dangerZoneContainer {
  padding: 16px 16px 16px 20px;
}

.selectBoxFolderButton {
  margin-top: 24px;
  background-image: url(../images/folder.svg);
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 16px;
  padding-left: 32px !important;
}

.selectedBoxFolder, .storedInterviewGuideText {
  display: inline-block;
  background-color: var(--color-gray6);
  background-position: 12px 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  margin-right: 16px;
  padding: 12px 16px 12px 48px;
  border: 1px solid var(--color-gray4);
  border-radius: 8px;
  color: var(--color-gray1);
}

.selectedBoxFolder {
  background-image: url(../images/folder-gray2.svg);
}

.editInterviewGuideButton {
  margin-top: 16px;
}

.storedInterviewGuideText {
  background-image: url(../images/file.svg);
}

.seeTranscriptionsLink {
  display: block;
  margin-top: 16px;
}

.assets {
  display: flex;
}

.asset {
  flex: 0 0 50%;

  .assetHeading {
    margin-bottom: 16px;
    color: var(--color-gray1);
  }

  .progressIndicator, .processingDone {
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .selectBoxFolderLink, .editInterviewGuideTextLink {
    margin-top: 8px;
  }
}

.step {
  height: 80px;
  overflow: hidden;

  .bulletItem {
    display: flex;
    align-items: center;
  }

  .bulletNumber {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: var(--color-gray4);
    color: var(--color-white);
  }

  .bulletCaption {
    margin-left: 8px;
    color: var(--color-gray4);
  }

  .bulletContent {
    padding: 24px 16px 32px;
    color: var(--color-white);
    margin-left: 8px;

    &.withBorder {
      border-left: 1px solid var(--color-gray4);
    }
  }

  .submitInterviewGuideButton {
    display: block;
    margin-top: 16px;
  }

  .finishSetupButton {
    display: block;
    margin-top: 16px;
  }

  &.active,
  &.finished {
    height: auto;
    opacity: 1;

    .bulletNumber {
      background-color: var(--color-gray2);
    }

    .bulletCaption {
      color: var(--color-gray1);
    }

    .bulletContent {
      color: var(--color-black);
    }
  }

  &.finished {
    .bulletNumber {
      background-color: transparent;
      background-image: url(../images/check-green.svg);
    }
  }
}

.detailsContainer,
.dangerZoneContainer {
  border-top: 1px solid var(--color-gray5);
  padding-top: 48px;
}

.dangerZoneContainer {
  margin-top: 48px;
  padding-bottom: 72px;
}
