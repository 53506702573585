.threeColumnsPanel {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: 
            "head head head"
            "left center right";
  grid-template-columns: 16% 1fr 32%;
  grid-template-rows: 10vh 1fr;
}

.twoColumnsPanel {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: 
            "head head"
            "left main";
  grid-template-columns: 16% 1fr;
  grid-template-rows: 10vh 1fr;
}

.mainNav {
  grid-area: head;
}

.leftColumn {
  grid-area: left;
}

.centerColumn {
  grid-area: center;
  height: 90vh;
}

.rightColumn {
  grid-area: right;
  height: 90vh;
}

.mainColumn {
  grid-area: main;
  height: 90vh;
}