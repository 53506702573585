.segmentsContainer {
  background-color: var(--color-gray6);
  overflow-y: auto;
  position: relative;

  &.frozen {
    overflow-y: hidden;
  }
}

.segments {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
}

.scrollingContainer {
  padding: 8px 16px 8px 20px;
}

.subnav {
  position: sticky;
  top: 0;
  z-index: 2;
  background: var(--color-gray6);

  .subnavTopic {
    margin-bottom: 8px;
    padding: 0px 16px 8px 20px;

    h1 {
      padding-top: 32px;
      display: flex;
      align-items: center;
    }

    .subnavTopicName {
    }

    .subnavTopicNumSegments {
      margin-left: 8px;
    }
  }

  .separator {
    height: 2px;
    background-color: var(--color-gray4);
  }

  .topicTags {
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;
  }

  .topicTag {
    margin: 0 4px 8px 0;
  }

  .controls {
    margin: 8px auto;
    padding: 8px 16px 8px 20px;
    display: flex;
    justify-content: space-between;

    .control {
      flex: 0 1 49%;
      padding: 8px 0 8px 36px;
      text-align: start;
      box-sizing: border-box;
      border: 1px solid var(--color-gray4);
      border-radius: 4px;
      background: var(--color-gray5);
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: 4px 50%;

      position: relative;

      .controlValue {
        margin-left: 4px;
      }
    }

    .dropdownListContainer {
      position: absolute;
      left: 0;
      top: 94%;
      min-height: 0;
      max-height: 0;
      min-width: 100%;
      overflow: hidden;

      transition: min-height 0s linear 200ms, max-height 0s linear 200ms;

      .dropdownList {
        margin-top: 8px;
        padding: 8px 0;
        border-radius: 4px;
        background-color: var(--color-gray2);
        color: var(--color-white);
        height: max-content;
        width: 100%;
        position: relative;

        transform: translateY(-110%);
        transition: transform 200ms linear;

        .dropdownListItem {
          cursor: pointer;
          padding: 0px 12px;

          div {
            padding: 8px 12px;
            border-radius: 4px;
          }

          &:hover {
            div {
              background-color: var(--color-gray1);
            }
          }

          &.selected {
            div {
              border: 1px solid var(--color-gray1);
            }
          }

          &.sticky {
            position: sticky;
            padding: 2px 12px;
            bottom: 0;
            background-color: var(--color-gray2);
            box-shadow: 0px 2px 24px var(--color-gray-shadow-hover);
          }
        }
      }

      &.active {
        min-height: calc(90vh - 178px); // 178px === subnav.height
        max-height: calc(90vh - 178px); // 178px === subnav.height
        overflow: auto;
        transition: min-height 0s linear, max-height 0s linear;

        .dropdownList {
          transform: translateY(0%);
        }
      }
    }

    .filter {
      background-image: url(../images/filter.svg);
    }

    .sort {
      background-image: url(../images/sort.svg);
    }
  }
}
