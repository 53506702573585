.topics {
  &.empty {
    color: var(--color-gray2);
    background-color: var(--color-gray6);
    .subnav {
      background-color: var(--color-gray6);
      .subheading {
        visibility: hidden;
      }
    }
  }
}

.subnav {
  position: relative;
  background-color: var(--color-white);

  min-height: 112px;
  max-height: 112px;

  padding: 0 16px 0 20px;

  .heading {
    margin-top: 32px;
  }

  .subheading {
    margin-top: 16px;
    color: var(--color-gray2);
    max-width: 50%;
  }

  .controls {
    position: absolute;
    top: 0;
    right: 0;

    .keepTopicsButton {
      margin-right: 16px;
      padding-left: 36px;

      background-repeat: no-repeat;
      background-position: 8px 50%;
      background-size: 24px;
      background-image: url(../images/plus-circle.svg);
    }
  }
}

.topicCards {
  height: calc(100% - 144px);
  overflow-y: auto;
  margin-top: -8px;

  display: flex;
  flex-flow: row wrap;
}

.topicCard {
  position: relative;
  flex: 1 1 calc(50% - 32px);
  max-width: calc(50% - 32px);

  &:nth-child(odd) {
    margin: 8px 10px 8px 20px;
  }

  &:nth-child(even) {
    margin: 8px 20px 8px 10px;
  }

  background: var(--color-gray6);
  border: 1px solid var(--color-gray5);
  border-radius: 8px;

  .topicCardLayer {
    border-radius: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    background: rgba(255, 255, 255, 0.8);
  }

  &.discarded {
    .topicCardLayer {
      display: unset;
    }

  .discardButton {
      transform: rotateZ(135deg);
    }
  }

  .discardButton {
    background-image: url(../images/close-circle.svg);
    width: 32px;
    height: 32px;
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.topicCardHead {
  position: relative;
  margin: 24px 0 0 0;
  border-bottom: 1px solid var(--color-gray5);

  .subhead {
    color: var(--color-gray1);
    padding-left: 16px;
  }

  > h2 {
    max-width: 44%;
  }

  > span {
    margin: 0 16px;
  }

  .controls {
    margin-left: auto;
  }

  .renameButton {
    visibility: hidden;
  }

  .seeTopicButton {
    margin-left: 16px;
  }
}

.topicTags {
  padding: 12px 16px 16px 16px;
  display: flex;
  flex-flow: row wrap;
}

.topicTagsCaption {
  color: var(--color-gray2);
}

.topicTag {
  margin: 4px;

  &:first-child {
    margin-left: 0;
  }
}

.renameTopicForm {
  width: 100%;
}

.sampleQuotes {
  margin: 16px 0;
  border-bottom: 1px solid var(--color-gray5);
  height: max-content;
  max-height: 148px;
  overflow: hidden;
  position: relative;

  transition: max-height 600ms linear;

  .subhead {
    color: var(--color-gray1);
    padding-left: 16px;
  }

  .sampleQuote {
    color: var(--color-gray1);
    margin: 8px 16px;
  }

  .seeMoreLayer {
    position: absolute;
    top: 65%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 1) 100%
    );

    transition: background 600ms linear;

    .seeMoreButton {
      background-color: var(--color-white);
    }
  }

  &.expanded {
    max-height: 1000px;

    .seeMoreLayer {
      top: 100%;

      .seeMoreButton {
        display: none;
      }
    }
  }
}

.topicCardFoot {
  display: flex;
  padding-bottom: 16px;

  > div {
    flex: 0 0 33.33%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .numSegments,
  .sentimentScore,
  .numInterviews {
    color: var(--color-gray1);
    padding: 6px 4px 6px 32px;

    background-repeat: no-repeat;
    background-position: 4px 50%;
    background-size: 24px;
  }

  .numSegments {
    background-image: url(../images/chat-gray3.svg);
  }
  .sentimentScore {
    background-image: url(../images/smiley.svg);
  }
  .numInterviews {
    background-image: url(../images/person-gray3.svg);
  }
}

.emptyTopics {
  height: calc(100% - 144px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 132px;
  background-color: var(--color-gray6);

  .topicIcons {
    position: relative;
    width: 64px;
    height: 64px;

    .topic16 {
      position: absolute;
      top: 0;
      right: -8px;
    }

    .topic24 {
      position: absolute;
      top: 8px;
      left: 0;
    }

    .topic48 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  h2,
  h4 {
    text-align: center;
    margin-top: 32px;
    max-width: 400px;
  }
}
