.questions {
  &.empty {
    color: var(--color-gray2);
    background-color: var(--color-gray6);
    .subnav {
      background-color: var(--color-gray6);
      .subheading {
        visibility: hidden;
      }
    }
  }
}

.subnav {
  background-color: var(--color-white);

  min-height: 75px;
  max-height: 75px;

  padding: 0 16px 0 20px;

  .heading {
    margin-top: 32px;
  }

  .subheading {
    margin-top: 16px;
    color: var(--color-gray2);
  }
}

.questionCards {
  height: calc(100% - 107px);
  overflow-y: auto;
  padding-top: 8px;
}

.questionsFooter {
  height: 72px;
  width: 100%;
  border-top: 1px solid var(--color-gray5);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;

  h4 {
    color: var(--color-gray2);
    margin-right: 16px;
  }
}

.questionCard {
  padding: 16px;
  margin: 8px 20px;

  background: var(--color-gray6);
  border: 1px solid var(--color-gray5);
  border-radius: 8px;

  &:hover {
    border-color: var(--color-gray3);
    .renameButton {
      visibility: initial;
    }
  }

  &.active {
    max-height: unset;
  }

  .questions {
    list-style-type: disc;
    list-style-position: inside;
    li {
      margin: 8px 16px;
    }
  }
}

.questionCardHead {
  align-items: center;
  padding: 8px 0;

  .controls {
    text-align: right;
  }

  .renameButton {
    visibility: hidden;
  }
}

.questionCardContent {
  margin-top: 16px;
}

.renameQuestionForm {
  width: 100%;
}

.emptyQuestions {
  height: calc(100% - 107px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 132px;
  background-color: var(--color-gray6);

  .questionIcons {
    position: relative;
    width: 64px;
    height: 64px;

    .question16 {
      position: absolute;
      top: 0;
      right: -8px;
    }

    .question24 {
      position: absolute;
      top: 8px;
      left: 0;
    }

    .question48 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  h2, h4 {
    text-align: center;
    margin-top: 32px;
    max-width: 400px;
  }
}
