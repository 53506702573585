.subnav {
  background-color: var(--color-white);

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95px;
  padding: 32px 16px 24px 20px;

  .heading {
    margin: 0;
  }

  &.withShadow {
    border-bottom: 1px solid var(--color-purple-shadow);
  }

  .copyClipboardButton {
    background-image: url(../images/copy.svg);
    background-repeat: no-repeat;
    background-position: 10px 50%;
    background-size: 16px;
    padding-left: 28px !important;

    &:disabled, &.copying {
      background-color: var(--color-gray4);
    }

    &:hover {
      background-color: var(--color-gray1);
    }

    &.success {
      background-image: url(../images/check.svg);
    }
  }
}

.intersection {
  height: 1px;
}

.scrollingContainer {
  overflow-y: auto;
  height: calc(100% - 95px);
  padding: 0px 16px 8px 20px;
}

.segments {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}

.topicContainer:not(:first-child) {
  margin-top: 16px;
}

.topicLabel {
  padding: 16px 0;
  color: var(--color-gray1);
}

.numFavorites {
  margin-left: 16px;
}

.removedSegment {
  background-color: var(--color-bg-yellow) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .removedText {
    color: var(--color-gray1);
  }
}

.emptyFavorites {
  height: calc(100% - 95px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 144px;

  .stars {
    position: relative;
    width: 64px;
    height: 64px;

    .star16 {
      position: absolute;
      top: 0;
      right: 0;
    }

    .star24 {
      position: absolute;
      top: 8px;
      left: 0;
    }

    .star48 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  h2, h4 {
    color: var(--color-gray3);
    text-align: center;
    margin-top: 32px;
    max-width: 300px;
  }
}