.interviews {
  background-color: var(--color-gray6);
  overflow-y: auto;

  &.empty {
    color: var(--color-gray2);
    background-color: var(--color-gray6);
    .subnav {
      background-color: var(--color-gray6);
      .numInterviews,
      .toggleCaption {
        visibility: hidden;
      }
    }
  }
}

.subnav {
  z-index: 2;
  background-color: var(--color-gray6);

  display: flex;
  align-items: center;
  padding: 32px 20px 24px 20px;

  position: sticky;
  top: 0;
}

.numInterviews {
  margin-left: 16px;
}

.toggleCaption {
  margin-left: auto;
  color: var(--color-gray2);
}

.interview {
  cursor: pointer;
  background: var(--color-white);
  border: 1px solid var(--color-gray5);
  box-sizing: border-box;
  border-radius: 8px;

  margin: 10px 16px 10px 20px;
  padding: 28px 16px 16px 16px;

  &:hover,
  &.active,
  &.active:hover {
    box-shadow: 0px 2px 24px var(--color-gray-shadow-hover);
    transform: translateY(-4px);
    z-index: 1;

    .tags {
      .addTagButton {
        visibility: initial;
      }
    }
  }

  &:hover {
    .interviewHead {
      .renameButton {
        visibility: inherit;
      }
    }
  }

  &.selected {
    border: 1px solid var(--color-gray2);
  }
}

.interviewHead {
  display: flex;
  align-items: center;
  padding: 8px 0;

  .displayName {
    max-width: 44%;
    color: var(--color-gray1);
  }

  .controls {
    position: relative;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .renameButton {
    visibility: hidden;
    margin-right: 32px;
  }
}

.interviewTags {
  margin-top: 18px;
}

.tagsCaption {
  color: var(--color-gray2);
}

.tags {
  padding-top: 12px;
  display: flex;
  flex-flow: row wrap;
}

.tag {
  margin: 4px;
  background-image: url(../images/close.svg);

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    position: relative;
    .removeTagHint {
      display: block;
    }
  }
}

.removeTagHint {
  display: none;
  position: absolute;
  top: -3.2em;
  right: -50px;
}

.toggleHint {
  display: none;
  position: absolute;
  top: -3.6em;
  right: -32px;

  &::after {
    top: 100%;
    right: 40px;
    left: unset;
  }
}

.addTagContainer {
  position: relative;
  visibility: hidden;

  &:hover {
    visibility: visible;

    .addTagButton {
      background-image: url(../images/add-gray2.svg);
    }
  }

  &.addTagContainerActive {
    visibility: visible;

    .addTagButton {
      background-image: url(../images/add-white.svg);
    }

    .additionalTags {
      display: initial;
    }
  }

  .addTagButton {
    margin: 4px;
    background-image: url(../images/add.svg);
  }

  .additionalTags {
    display: none;
    position: absolute;
    width: max-content;
    top: 2em;
    left: 4px;
  }
}

.toggleButton:hover {
  + .toggleHint {
    display: initial;
  }
}

.interviewer {
  background-color: var(--color-white-translucent);

  .renameButton {
    display: none;
  }
}

.emptyInterviews {
  height: calc(100% - 107px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-gray6);
  padding-top: 152px;

  .peopleIcons {
    position: relative;
    width: 64px;
    height: 64px;

    .people18 {
      position: absolute;
      top: 0;
      right: -8px;
    }

    .people24 {
      position: absolute;
      top: 8px;
      left: 0;
    }

    .people48 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  h2,
  h4 {
    text-align: center;
    margin-top: 32px;
    max-width: 400px;
  }
}
