// https://codepen.io/mallendeo/pen/eLIiG

.toggle {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .toggleButton {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .toggleButton {
    outline: 0;
    display: block;
    width: 32px;
    height: 16px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .toggleButton:after {
    left: 50%;
  }
}

// themes
.toggle {
  + .toggleButton {
    background: var(--color-gray5);
    border-radius: 2em;
    transition: all 0.4s ease;
    &:after {
      border-radius: 50%;
      background: var(--color-white);
      border: 2px solid var(--color-gray3);
      transition: all 0.2s ease;
    }
  }

  &:checked + .toggleButton {
    background: var(--color-green);
    &:after {
      background: var(--color-mint);
      border-color: var(--color-green);
    }
  }
}
