.segment {
  background-color: var(--color-white);
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--color-gray5);
  box-shadow: 0px 1px 6px var(--color-gray-shadow);
  padding: 16px;
  position: relative;
  transition: transform 0.4s ease-out;

  &:hover, &.active, &.active:hover {
    box-shadow: 0px 2px 24px var(--color-gray-shadow-hover);
    transform: translateY(-4px);
    z-index: 1;

    .topics {
      .addTagButton {
        visibility: initial;
      }
    }
  }

  &.selected {
    border-color: var(--color-gray2);
  }

  .speaker {
    margin: 8px 0;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
  }

  .topics {
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;

    .segmentTopic {
      position: relative;
      margin: 4px;
      background-image: url(../images/close.svg);

      &:first-child {
        margin-left: 0;
      }

      .removeTopicHint {
        display: none;
        position: absolute;
        top: -3.2em;
        right: -58px;
      }

      &:hover {
        background-image: url(../images/close-gray1.svg);

        .removeTopicHint {
          display: initial;
        }
      }
    }

    .addTagContainer {
      position: relative;
      visibility: hidden;

      &:hover {
        visibility: visible;

        .addTagButton {
          background-image: url(../images/add-gray2.svg);   
        }
      }

      &.addTagContainerActive {
        visibility: visible;

        .addTagButton {
          background-image: url(../images/add-white.svg);
        }

        .additionalTags {
          display: initial;
        }
      }

      .addTagButton {
        margin: 4px;
        background-image: url(../images/add.svg);
      }
      
      .additionalTags {
        display: none;
        position: absolute;
        width: max-content;
        top: 2em;
        left: 4px;
      }
    }
  }

  .star {
    cursor: pointer;
    position: absolute;
    border: none;
    height: 24px;
    width: 24px;
    top: 8px;
    right: 8px;
    background-color: transparent;
    background-image: url(../images/star-outline.svg);
    background-repeat: no-repeat;

    &:hover {
      background-image: url(../images/star-outline-gold.svg);
    }

    &.favorite {
      background-image: url(../images/star-fill-gold.svg);
    }
  }
}
