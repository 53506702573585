.projectsRoot {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "head head"
    "left right";
  grid-template-columns: 58% 1fr;
  grid-template-rows: 10vh 1fr;
}

.mainNav {
  grid-area: head;
  border-bottom: 1px solid var(--color-gray5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectsList {
  grid-area: left;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  padding: 16px 8%;
  position: relative;

  h1 {
    margin-bottom: 32px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .scrollingContainer {
    overflow-y: auto;
    height: calc(90vh - 60px);
    padding-left: 8px;
    padding-right: 8px;
  }

  .backgroundLayer {
    opacity: 0.4;
  }
}

.createProjectForm {
  grid-area: right;
  background-color: var(--color-gray6);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 0 48px;

  position: relative;

  .signOutLink {
    color: var(--color-gray3);

    position: absolute;
    right: 16px;
    bottom: 8px;
  }

  h1 {
    margin-bottom: 32px;
  }

  .projectName {
    color: var(--color-gray2);
  }

  input {
    margin-top: 8px;
    width: 100%;
  }

  button {
    display: block;
    margin-top: 16px;
  }
}

.logo {
  background-image: url(../images/logo.svg);
  background-repeat: no-repeat;
  background-position: 0%;
  height: 24px;
  width: 61px;
}

.progressIndicator {
  background-image: url(../images/progress-indicator.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 63px 9px;
  padding: 16px 16px 16px 72px;
}

.projectCard {
  padding: 28px 20px;
  margin: 0 4px 24px 4px;

  background: var(--color-gray6);
  border: 1px solid var(--color-gray5);
  border-radius: 8px;

  .projectHead {
    display: flex;
    justify-content: space-between;

    h2 {
      flex: 1 1 auto;
    }

    .viewProjectButton {
      flex: 0 0 122px;
    }
  }

  .projectStatusText {
    display: flex;
    color: var(--color-gray2);
  }

  .numInterviews {
    margin-left: 16px;
  }
}

.loadingProjects {
  position: relative;
}

.emptyProjects {
  position: relative;
  display: flex;
  flex-flow: column wrap;

  padding: 22% 14% 0 14%;
  color: var(--color-gray1);

  .headings {
    opacity: 1;
    transition: opacity 800ms ease-in;  
  }

  &.loadingProjects {
    .headings {
      opacity: 0;
    }
  }


  h1.large {
    font-weight: 500;
    font-size: 32px;
    line-height: 160%;
    width: 330px;
  }

  h2.large {
    font-weight: 500;
    font-size: 24px;
    line-height: 160%;

    width: 330px;
    margin-top: 48px;
  }
}

.backgroundLayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;

  transition: opacity 800ms ease-out;

  .object {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .group167 {
    background-image: url(../images/bg_group167.svg);
    width: 37%;
    height: 32%;
    bottom: 6%;
    right: -1.4%;
  }

  .ellipse195 {
    background-image: url(../images/bg_ellipse195.svg);
    width: 7%;
    height: 7%;
    bottom: 36%;
    right: 31%;
  }

  .ellipse201 {
    background-image: url(../images/bg_ellipse201.svg);
    width: 7%;
    height: 10%;
    bottom: 46.6%;
    right: 25.7%;
  }

  .ellipse200 {
    background-image: url(../images/bg_ellipse200.svg);
    width: 7%;
    height: 7%;
    bottom: 59.6%;
    right: 37.8%;
  }

  .rectangle173 {
    background-image: url(../images/bg_rectangle173.svg);
    width: 11.3%;
    height: 16.6%;
    bottom: 63.4%;
    right: 34.1%;
  }

  .ellipse206 {
    background-image: url(../images/bg_ellipse206.svg);
    width: 7%;
    height: 7%;
    top: 18.3%;
    left: -0.4%;
  }

  .rectangle179 {
    background-image: url(../images/bg_rectangle179.svg);
    width: 9%;
    height: 9%;
    top: 10.7%;
    left: 25.2%;
  }

  .ellipse214 {
    background-image: url(../images/bg_ellipse214.svg);
    width: 12.3%;
    height: 12.2%;
    top: -3.2%;
    left: 6.3%;
  }
}
