.globalErrorContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;

  button {
    min-width: 180px;
    text-align: center;
  }
}

body > iframe {
  display: none !important;
}
