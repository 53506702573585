.form {
  position: relative;

  input[type=text] {
    width: 100%;
  }

  .formControls {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .saveButton {
    margin-left: 24px;
  }

  .clearInputButton {
    cursor: pointer;
    background-image: url(../images/close.svg);
    background-repeat: no-repeat;
    background-size: 24px;
    width: 24px;
    height: 24px;

    position: absolute;
    top: 8px;
    right: 8px;
  }
}