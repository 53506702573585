.topics {
  &.empty {
    color: var(--color-gray2);
    background-color: var(--color-gray6);
    .subnav {
      background-color: var(--color-gray6);
      .subheading {
        visibility: hidden;
      }
    }
  }
}

.subnav {
  background-color: var(--color-white);

  min-height: 75px;
  max-height: 75px;

  padding: 0 16px 0 20px;

  .heading {
    margin-top: 32px;
  }

  .subheading {
    margin-top: 16px;
    color: var(--color-gray2);
  }
}

.topicCards {
  height: calc(100% - 107px);
  overflow-y: auto;
  padding-top: 8px;

  display: flex;
  flex-flow: row wrap;
}

.topicsFooter {
  height: 72px;
  width: 100%;
  border-top: 1px solid var(--color-gray5);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;

  h4 {
    color: var(--color-gray2);
    margin-right: 16px;
  }
}

.topicCard {
  flex: 1 1 calc(50% - 32px);
  padding: 16px;
  max-width: calc(50% - 32px);

  &:nth-child(odd) {
    margin: 8px 10px 8px 20px;
  }

  &:nth-child(even) {
    margin: 8px 20px 8px 10px;
  }

  background: var(--color-gray6);
  border: 1px solid var(--color-gray5);
  border-radius: 8px;

  &:hover {
    border-color: var(--color-gray3);
    .renameButton {
      visibility: initial;
    }
  }

  &.active {
    max-height: unset;
  }
}

.topicCardHead {
  display: flex;
  align-items: center;
  padding: 8px 0;

  > h2 {
    max-width: 44%;
  }

  > span {
    margin: 0 16px;
  }

  .controls {
    margin-left: auto;
  }

  .renameButton {
    visibility: hidden;
  }

  .seeTopicButton {
    margin-left: 16px;
  }
}

.topicCardContent {
  margin-top: 16px;
}

.topicTags {
  padding-top: 12px;
  display: flex;
  flex-flow: row wrap;
}

.topicTagsCaption {
  color: var(--color-gray2);
}

.topicTag {
  margin: 4px;

  &:first-child {
    margin-left: 0;
  }
}

.renameTopicForm {
  width: 100%;
}

.emptyTopics {
  height: calc(100% - 107px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 132px;
  background-color: var(--color-gray6);

  .topicIcons {
    position: relative;
    width: 64px;
    height: 64px;

    .topic16 {
      position: absolute;
      top: 0;
      right: -8px;
    }

    .topic24 {
      position: absolute;
      top: 8px;
      left: 0;
    }

    .topic48 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  h2, h4 {
    text-align: center;
    margin-top: 32px;
    max-width: 400px;
  }
}
