.toastContainer {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: absolute;
  top: 6vh;

  opacity: 0.0;
  transition: opacity 0.8s ease-out;

  &.visible {
    opacity: 1.0;
  }
}

.toast {  
  background: var(--color-mint);
  border: 1px solid var(--color-green);
  box-shadow: 0px 2px 16px var(--color-purple-shadow);
  border-radius: 8px;
  padding: 16px;
}
