.mainNav {
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 16px;
  border-bottom: 1px solid var(--color-gray5);

  .projectName {
    margin: 0;
    padding-left: 32px;
    background-image: url(../images/gear.svg);
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: 20px;
    line-height: 1em;
  }

  .logo {
    background-image: url(../images/logo.svg);
    background-repeat: no-repeat;
    background-position: 0%;
    height: 24px;
    width: 61px;
  }
}
