@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");

:root {
  --color-black: #0f0c25;
  --color-white: #fff;
  --color-bg-yellow: #fffcde;
  --color-highlight-yellow: #fffac9;
  --color-green: #00bba5;
  --color-mint: #e6fffc;
  --color-gray1: #423d60;
  --color-gray2: #676284;
  --color-gray3: #a8a4bb;
  --color-gray4: #cfced6;
  --color-gray5: #e5e4ee;
  --color-gray6: #f8f8fe;
  --color-purple: #3b3273;
  --color-red: #ec305d;

  --color-white-translucent: rgba(255, 255, 255, 0.6);

  --color-gray5-blurred: rgba(229, 228, 238, 0.5);

  --color-black-shadow: rgba(0, 0, 0, 0.2);
  --color-gray-shadow: rgba(189, 189, 189, 0.25);
  --color-gray-shadow2: rgba(66, 61, 96, 0.25);
  --color-gray-shadow-hover: rgba(15, 12, 37, 0.25);
  --color-purple-shadow: rgba(168, 164, 187, 0.25);
}

*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: var(--color-black);
}

h1 {
  font-size: 24px;
  font-weight: 500;
}

h2 {
  font-size: 18px;
  font-weight: 600;
}

h3 {
  font-size: 16px;
  font-weight: 600;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.subhead {
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
}

.text-italic {
  font-size: 14px;
  line-height: 160%;
  font-weight: 400;
  font-style: italic;
}

.smallLabel {
  font-size: 14px;
  font-weight: 500;
}

.iconLabel {
  font-size: 12px;
  font-weight: 500;
}

button,
a {
  cursor: pointer;
}

.link {
  text-decoration: underline;
}

.button.primary {
  color: var(--color-white);
  background-color: var(--color-purple);
  padding: 8px 16px;
  border-radius: 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.button.primary:disabled,
.button.primary:disabled:hover {
  color: var(--color-gray6);
  background-color: var(--color-gray4);
}
.button.primary:hover {
  background-color: var(--color-gray1);
}
.button.primary.selected {
  box-shadow: inset 0px 1px 2px var(--color-black);
}
.button.primary.success,
.button.primary.success:hover {
  background-color: var(--color-green);
}

.button.secondary {
  color: var(--color-purple);

  padding: 8px 16px;
  border: 1px solid var(--color-gray1);
  border-radius: 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.button.secondary:disabled,
.button.secondary:disabled:hover {
  color: var(--color-gray6);
  background-color: var(--color-gray4);
}
.button.secondary:hover {
  color: var(--color-white);
  background-color: var(--color-purple);
}
.button.secondary.alert {
  color: var(--color-red);
  border-color: var(--color-red);
}
.button.secondary.alert:hover {
  color: var(--color-white);
  background-color: var(--color-red);
}

.linkButton {
  color: var(--color-gray1);
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
}
.linkButton.primary {
  color: var(--color-purple);
}

.numberBadge {
  display: inline-block;
  text-align: center;
  min-width: 2.4em;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-gray2);
  background-color: var(--color-gray6);
  border-radius: 34px;
  padding: 8px;
}

.numberBadge.dark {
  background-color: var(--color-gray5);
}

.keyword {
  padding: 4px 8px;
  color: var(--color-gray1);
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-gray4);
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
}

.topic,
.cohort {
  cursor: pointer;
  padding: 12px 24px 12px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 0;
  color: var(--color-gray1);

  border: 1px solid var(--color-gray5);
  border-radius: 48px;

  background-repeat: no-repeat;
  background-position: calc(100% - 4px) 50%;
  background-size: 16px;
}

.topic {
  background-color: var(--color-gray6);
}

.cohort {
  background-color: var(--color-mint);
}

.topic-action,
.cohort-action {
  cursor: pointer;
  padding: 12px 24px 12px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 0;
  color: var(--color-gray2);

  border: 1px dashed var(--color-gray4);
  border-radius: 48px;

  background-color: var(--color-white);
  background-repeat: no-repeat;
  background-position: calc(100% - 4px) 50%;
  background-size: 16px;
}

.topic-action.selected,
.cohort-action.selected {
  color: var(--color-white);
  background-color: var(--color-gray2);
  border: 1px solid var(--color-gray2);
}

.topic-action-list,
.cohort-action-list {
  background: var(--color-gray2);
  box-shadow: 0px 2px 24px var(--color-gray-shadow-hover);
  border-radius: 8px;
  padding: 10px 8px;
  width: 240%;
}

.topic-action-list-caption,
.cohort-action-list-caption {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-white);
  padding: 10px;
}

.topic-action-list-item,
.cohort-action-list-item {
  font-weight: 400;
  font-weight: normal;
  font-size: 16px;
  color: var(--color-white);
  padding: 10px;
}

.topic-action-list-item:hover,
.cohort-action-list-item:hover {
  background: var(--color-gray1);
  border-radius: 8px;
}

.tooltip {
  font-size: 14px;
  line-height: 114%;
  padding: 12px 16px;
  width: max-content;

  background-color: var(--color-gray1);
  border: 1px solid var(--color-gray1);
  border-radius: 4px;
  box-shadow: 0px 6px 12px var(--color-black-shadow);
  color: var(--color-white);
  text-align: center;
}

.tooltip::after {
  content: " ";
  width: 16px;
  height: 8px;
  background-color: var(--color-gray1);
  clip-path: polygon(0 0, 50% 100%, 100% 0%);
  position: absolute;
  top: 100%;
  left: 40%;
}

input[type="text"], textarea {
  display: block;
  padding: 8px 6px;
  border: 1px solid var(--color-gray5);
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2em;
}

input[type="text"]:focus, textarea:focus {
  border-color: var(--color-purple);
}

.loading-layer {
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  width: 0;
  height: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes loading-indicator {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.loading-layer .loading-indicator {
  display: none;
  width: 8vw;
  height: 8vw;

  border: 4px solid var(--color-gray1);
  border-bottom-color: transparent;
  border-radius: 50%;

  animation-name: loading-indicator;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.loading-layer.active {
  bottom: 0;
  right: 0;
  width: unset;
  height: unset;
}

.loading-layer.active .loading-indicator {
  display: block;
}
