.App {
  box-sizing: border-box;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.appRoot {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "head"
    "body";
  grid-template-rows: 10vh 1fr;

  &.loaded {
    .appContent {
      opacity: 1;
    }
  }
}

.mainNav {
  grid-area: head;
  border-bottom: 1px solid var(--color-gray5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.appContent {
  grid-area: body;
  height: calc(100% - 10vh);
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 200ms linear 1s;
}

.logo {
  background-image: url(./images/logo.svg);
  background-repeat: no-repeat;
  background-position: 0%;
  height: 24px;
  width: 61px;
}

.signinButton {
  display: block;
  margin-top: 16px;
  width: 191px;
  height: 46px;
  background-image: url(./images/google-signin.png);
  background-repeat: no-repeat;
  background-size: contain;
}