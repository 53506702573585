.emptyTranscript {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 96px;
  text-align: center;

  color: var(--color-gray3);
}

.transcript {
  position: relative;
  overflow-y: auto;

  .transcriptHeading {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 32px 16px 16px 20px;
    background: #ffffff;
    opacity: 0.95;
    box-shadow: 0px 1px 8px var(--color-gray-shadow2);
  }

  .segments {
    list-style: none;
    margin: 0;
    padding: 16px 20px;

    .segment {
      padding: 8px 0;

      .text {
        margin-top: 4px;

        &.selected {
          background-color: var(--color-highlight-yellow);
        }
      }
    }
  }
}
